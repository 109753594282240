<script setup lang="ts">
import { useOnline } from '@vueuse/core';
import Logo from '@mui/assets/logos/medmastery-color.svg?component';

const { user } = useUserStore();
const isOnline = useOnline();
const { dispatchMessage } = useSystemMessages();

const showVerifyYourAccountModal = ref(false);

if (user && user.value?.isVerified === false) {
  showVerifyYourAccountModal.value = true;
}

watch(isOnline, (newValue: boolean) => {
  if (newValue) {
    dispatchMessage('Back online.', 'success', 'Your browser is back online.', 10000);
    return;
  }

  dispatchMessage('You are offline.', 'warn', 'Your browser went offline, please check your internet connection.', 15000);
});

const config = useRuntimeConfig();
</script>

<template>
  <div class="bg-neutral-5 lg:bg-white flex flex-col h-dvh">
    <SystemMessages />

    <header class="lg:fixed flex justify-center top-0 lg:bg-transparent bg-white lg:p-8 pt-2.5 pb-1.5">
      <NuxtLink
        :to="config.public.publicWebsite"
        :external="true"
      >
        <Logo />
      </NuxtLink>
    </header>

    <slot />
  </div>
</template>

<style lang="scss">
.page {
  height: 100%;
}
</style>
